import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit, InsertChart } from '@mui/icons-material';
import { filter, find, get, isEmpty, keys, map, reduce } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import useSetState from '../../../../../hooks/useSetState';
import {
  Model,
  ModelInstance,
  RowIdentifier,
  Scenario,
  Timescale,
} from '../../../../../types/models';
import { useData } from '../../../../../hooks/useData';
import { parseId } from '../../../../../utils/parsing';
import { useProfile } from '../../../../../hooks/useProfile';
import * as API from '../../../../../services/API';

import { ISelectTimescaleValueProps } from '../../../../Visualization/TimescaleValuePickers/SelectTimescaleValue';

import { getDataAtIntervals } from '../../../../../utils/getDataAtIntervals';
import { PortfolioDataSourceStatusEnum } from '../../../types';

import FullscreenProgress from '../../../../shared/FullScreenProgress';

import { IPortfolioReportStateData } from '../../../index';

import EditChartPanel, { WaterfallColumns } from './EditChartPanel';
import GenerateChartPanel from './GenerateChartPanel';

interface WaterfallState {
  modelId: Model['id'] | undefined;
  modelInstanceId: ModelInstance['id'] | undefined;
  fromScenarioId: Scenario['id'] | undefined;
  toScenarioId: Scenario['id'] | undefined;
  outputId: RowIdentifier['id'] | undefined;
  timescale: Timescale | undefined;
  chartType: string | undefined;
  isPanelCollapsed: boolean;
  isEditChartPanelOpen: boolean;
  isGenerateChartPanelOpen: boolean;
  waterFallData: any[];
  chartLoading: boolean;
  blobURL: string | undefined;
  formatKey: number | null;
  timeScaleValue: ISelectTimescaleValueProps['value'] | string[] | null;
  columns: WaterfallColumns[];
  dimensionInstances: RowIdentifier[];
}

interface WaterfallProps {
  selectedReport?: IPortfolioReportStateData;
  isThereAnUnsavedComponent: (bool: boolean) => void;
  refresh: () => void;
  navigateToReportsHomePage: () => void;
}

const Waterfall: FunctionComponent<WaterfallProps> = ({
  selectedReport,
  isThereAnUnsavedComponent,
  refresh,
  navigateToReportsHomePage,
}) => {
  const [state, setState] = useSetState<WaterfallState>({
    chartLoading: false,
    blobURL: undefined,
    isPanelCollapsed: false,
    isEditChartPanelOpen: true,
    isGenerateChartPanelOpen: false,
    modelId: undefined,
    modelInstanceId: undefined,
    fromScenarioId: undefined,
    toScenarioId: undefined,
    outputId: undefined,
    timescale: undefined,
    chartType: undefined,
    waterFallData: [],
    formatKey: null,
    timeScaleValue: null,
    columns: [],
    dimensionInstances: [],
  });

  const profile = useProfile();

  const [searchParams] = useSearchParams();

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(searchParams.get('client'));

  useEffect(() => {
    const runReport = searchParams.get('runReport');
    (async () => {
      if (
        selectedReport &&
        selectedReport.ModelInstanceID &&
        runReport === 'true'
      ) {
        const rowIdentifiers = await API.load(
          `/instances/${selectedReport.ModelInstanceID}/row_identifiers`
        );
        setState(
          {
            isGenerateChartPanelOpen: false,
            isEditChartPanelOpen: false,
            chartLoading: true,
          },
          () => {
            const chartType = selectedReport.Metadata.WaterfallChart.DateId_End
              ? 'cumulative'
              : 'pointintime';
            generateChart(
              selectedReport.Metadata.WaterfallChart.OutputName,
              selectedReport.Metadata.WaterfallChart.Timescale,
              selectedReport.Metadata.WaterfallChart.ScenarioId_A,
              selectedReport.Metadata.WaterfallChart.ScenarioId_B,
              chartType,
              chartType === 'pointintime'
                ? selectedReport.Metadata.WaterfallChart.DateId_Start
                : [
                    selectedReport.Metadata.WaterfallChart.DateId_Start,
                    selectedReport.Metadata.WaterfallChart.DateId_End,
                  ],
              map(selectedReport.Metadata.WaterfallColumns, (i, idx) => {
                return {
                  id: idx + 1,
                  name: i.Name,
                  inputs: filter(
                    filter(rowIdentifiers, { Type: 'Input' }),
                    (f) => i.InputNames.includes(f.Name)
                  ),
                };
              }) as any[],
              filter(
                filter(rowIdentifiers, { Type: 'DimensionInstance' }),
                (f) =>
                  selectedReport?.Metadata.WaterfallChart.RowIdentifierIDs.includes(
                    f.id
                  )
              )
            );
          }
        );
      }
    })();
  }, [searchParams, selectedReport]);

  useEffect(() => {
    (async () => {
      if (selectedReport?.ModelInstanceID) {
        const rowIdentifiers = await API.load(
          `/instances/${selectedReport?.ModelInstanceID}/row_identifiers`
        );
        setState({
          modelId: selectedReport?.ModelID,
          modelInstanceId: selectedReport?.ModelInstanceID,
          ...(selectedReport?.Metadata && {
            outputId: find(filter(rowIdentifiers, { Type: 'Output' }), {
              Name: selectedReport?.Metadata.WaterfallChart.OutputName,
            })?.id,
            fromScenarioId:
              selectedReport?.Metadata.WaterfallChart.ScenarioId_A,
            toScenarioId: selectedReport?.Metadata.WaterfallChart.ScenarioId_B,
            chartType: selectedReport?.Metadata.WaterfallChart.DateId_End
              ? 'cumulative'
              : 'pointintime',
            timescale: selectedReport?.Metadata.WaterfallChart.Timescale,
            timeScaleValue:
              selectedReport?.Metadata.WaterfallChart.DateId_End === null
                ? selectedReport?.Metadata.WaterfallChart.DateId_Start
                : [
                    selectedReport?.Metadata.WaterfallChart.DateId_Start,
                    selectedReport?.Metadata.WaterfallChart.DateId_End,
                  ],
            columns: map(
              selectedReport?.Metadata.WaterfallColumns,
              (i, idx) => {
                return {
                  id: idx + 1,
                  name: i.Name,
                  inputs: filter(
                    filter(rowIdentifiers, { Type: 'Input' }),
                    (f) => i.InputNames.includes(f.Name)
                  ),
                };
              }
            ),
            dimensionInstances: filter(
              filter(rowIdentifiers, { Type: 'DimensionInstance' }),
              (f) =>
                selectedReport?.Metadata.WaterfallChart.RowIdentifierIDs.includes(
                  f.id
                )
            ),
            formatKey: (
              find(rowIdentifiers, {
                id: find(filter(rowIdentifiers, { Type: 'Output' }), {
                  Name: selectedReport?.Metadata.WaterfallChart.OutputName,
                })?.id,
              }) as RowIdentifier
            ).FormatKey,
          }),
        });
      }
    })();
  }, [selectedReport]);

  const { data } = useData<{
    rowIdentifiers?: RowIdentifier[];
    model?: Model;
    modelInstance?: ModelInstance;
    scenarios?: Scenario[];
  }>(
    () => ({
      rowIdentifiers:
        state.modelInstanceId !== undefined
          ? `/instances/${state.modelInstanceId}/row_identifiers`
          : undefined,
      model:
        clientId !== undefined && state.modelId !== undefined
          ? `/clients/${clientId}/models/${state.modelId}`
          : undefined,
      modelInstance:
        clientId !== undefined && state.modelId !== undefined
          ? `/instances/${state.modelInstanceId}`
          : undefined,
      scenarios:
        state.modelInstanceId !== undefined
          ? `/instances/${state.modelInstanceId}/scenarios_load/finished`
          : undefined,
    }),
    [state.modelInstanceId, state.modelId]
  );

  const outputs = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Output' }),
    [state.modelInstanceId, data]
  );

  const handleCloseEditChartPanel = useCallback(() => {
    setState({
      isEditChartPanelOpen: false,
      isGenerateChartPanelOpen: true,
    });
  }, [state.isEditChartPanelOpen, state.isGenerateChartPanelOpen]);

  const generateChart = async (
    outputName: RowIdentifier['Name'],
    timescale: Timescale,
    fromScenarioId: Scenario['id'],
    toScenarioId: Scenario['id'],
    chartType: string,
    timeScaleValue: any,
    columns: WaterfallColumns[],
    dimensionInstances: RowIdentifier[]
  ) => {
    if (
      outputName &&
      fromScenarioId &&
      toScenarioId &&
      timeScaleValue &&
      !isEmpty(columns)
    ) {
      setState({
        chartLoading: true,
        isEditChartPanelOpen: false,
        isGenerateChartPanelOpen: true,
      });
      try {
        const result: {
          ForecastDataRefresh: number;
          ForecastDataRefreshID: number;
          RowIdentifier: number;
          RowIdentifierID: number;
          Status: PortfolioDataSourceStatusEnum;
          Status_Message: string | null;
          created_at: string;
          id: number;
        }[] = await API.create(`/chart_refresh/waterfall`, {
          WaterfallChart: {
            Timescale: timescale,
            OutputName: outputName,
            RowIdentifierIDs: dimensionInstances.map(
              (dimensionInstance) => dimensionInstance.id
            ),
            DateId_Start:
              chartType === 'pointintime' ? timeScaleValue : timeScaleValue[0],
            DateId_End: chartType === 'pointintime' ? null : timeScaleValue[1],
            ScenarioId_A: fromScenarioId,
            ScenarioId_B: toScenarioId,
          },
          WaterfallColumns: map(columns, (column, idx) => {
            return {
              Order: idx + 1,
              Name: column.name,
              InputNames: map(column.inputs, 'Name'),
            };
          }),
        });
        if (result) {
          const waterfallData = await getDataAtIntervals(
            `/chart_refresh/${result[0].ForecastDataRefreshID}`,
            'Status'
          );
          if (waterfallData.Status === PortfolioDataSourceStatusEnum.FINISHED) {
            setState({
              waterFallData: waterfallData.data,
              isEditChartPanelOpen: false,
              isGenerateChartPanelOpen: true,
              chartLoading: false,
              blobURL: waterfallData?.ExcelBlobURL,
            });
          } else {
            setState({
              isEditChartPanelOpen: true,
              isGenerateChartPanelOpen: false,
              chartLoading: false,
              blobURL: undefined,
            });
            enqueueSnackbar(waterfallData.Status_Message, { variant: 'error' });
          }
          if (waterfallData.Status === PortfolioDataSourceStatusEnum.FAILED) {
            navigateToReportsHomePage();
          }
        } else {
          enqueueSnackbar('Please select all required fields', {
            variant: 'error',
          });
        }
      } catch (error: any) {
        enqueueSnackbar(error.body.message, { variant: 'error' });
        setState({
          isEditChartPanelOpen: true,
          isGenerateChartPanelOpen: false,
          chartLoading: false,
        });
        navigateToReportsHomePage();
        return;
      }
    }
  };

  const handleSaveEditChartPanel = async (chartData: {
    modelId?: Model['id'];
    modelInstanceId?: ModelInstance['id'];
    fromScenarioId: Scenario['id'];
    toScenarioId: Scenario['id'];
    outputId: RowIdentifier['id'];
    timescale: Timescale;
    chartType: string;
    timeScaleValue: ISelectTimescaleValueProps['value'] | string[] | null;
    columns: WaterfallColumns[];
    dimensionInstances: RowIdentifier[];
  }) => {
    const rowIdentifiers = await API.load(
      `/instances/${chartData.modelInstanceId}/row_identifiers`
    );
    setState({
      modelId: chartData.modelId,
      modelInstanceId: chartData.modelInstanceId,
      fromScenarioId: chartData.fromScenarioId,
      toScenarioId: chartData.toScenarioId,
      outputId: chartData.outputId,
      timescale: chartData.timescale,
      chartType: chartData.chartType,
      timeScaleValue: chartData.timeScaleValue,
      columns: chartData.columns,
      dimensionInstances: chartData.dimensionInstances,
      formatKey: (
        find(rowIdentifiers, { id: chartData.outputId }) as RowIdentifier
      ).FormatKey,
    });
    generateChart(
      find(rowIdentifiers, { id: chartData.outputId })?.Name || '',
      chartData.timescale,
      chartData.fromScenarioId,
      chartData.toScenarioId,
      chartData.chartType,
      chartData.timeScaleValue,
      chartData.columns,
      chartData.dimensionInstances
    );
  };

  const dimensions = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Dimension' }),
    [data]
  );

  const selectedDimensionInstances = useMemo(
    () =>
      reduce(
        state.dimensionInstances,
        (a: Record<string, RowIdentifier[]>, c, idx, arr) => {
          const findDimensionByDimNumber = find(dimensions, {
            DimNumber: c.DimNumber,
          }) as RowIdentifier;
          a[findDimensionByDimNumber?.Name] = filter(arr, {
            DimNumber: get(findDimensionByDimNumber, 'DimNumber'),
          });
          return a;
        },
        {}
      ),
    [state.dimensionInstances, dimensions]
  );

  return (
    <Box>
      <Grid container sx={{ my: 2, width: '100%', height: '100%' }} spacing={0}>
        <Grid
          xs={state.isPanelCollapsed ? 0.75 : 2.5}
          sx={{
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
        >
          <Card
            sx={{
              height: '100%',
              mr: 2,
            }}
          >
            <CardContent
              sx={{
                background: 'white',
                '& hr': {
                  my: 1,
                },
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {!state.isPanelCollapsed && (
                  <Grid sm={6}>
                    <Typography variant="h6">Chart Data</Typography>
                  </Grid>
                )}
                {state.isGenerateChartPanelOpen && (
                  <Grid sm={6} container justifyContent={'flex-end'}>
                    <Tooltip title="Edit Chart" arrow>
                      <IconButton
                        onClick={() =>
                          setState({
                            isEditChartPanelOpen: true,
                            isGenerateChartPanelOpen: false,
                          })
                        }
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
              <Divider />
              {state.isPanelCollapsed ? (
                <Grid
                  container
                  direction="column"
                  sx={{
                    display: 'block',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  spacing={1}
                >
                  <Grid xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                    {!state.isEditChartPanelOpen && (
                      <>
                        {state.isPanelCollapsed && (
                          <Tooltip title="Edit Chart" arrow>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setState({
                                  isEditChartPanelOpen: true,
                                  isGenerateChartPanelOpen: false,
                                })
                              }
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                    {state.isPanelCollapsed && (
                      <Tooltip title="Format Chart" arrow>
                        <IconButton
                          size="small"
                          onClick={() =>
                            setState({
                              isEditChartPanelOpen: false,
                              isGenerateChartPanelOpen: false,
                            })
                          }
                        >
                          <InsertChart fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid xs={12}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Model
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      {state.modelId ? data?.model?.Name : ''}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid xs={12}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Model Instance
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography sx={{ wordBreak: 'break-all' }}>
                        {state.modelInstanceId ? data?.modelInstance?.Name : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid xs={12}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Scenarios
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      {state.fromScenarioId
                        ? get(
                            find(
                              data.scenarios,
                              (i) => i.id === state.fromScenarioId
                            ) as Scenario,
                            'Name',
                            ''
                          )
                        : ''}{' '}
                      -{' '}
                      {state.toScenarioId
                        ? get(
                            find(
                              data.scenarios,
                              (i) => i.id === state.toScenarioId
                            ) as Scenario,
                            'Name',
                            ''
                          )
                        : ''}
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid container sx={{ mt: 2 }}>
                <Grid xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Output
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  {state.outputId
                    ? find(outputs, {
                        id: state.outputId,
                      })?.Name
                    : ''}
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Timescale
                  </Typography>
                </Grid>
                <Grid xs={12}>{state.timescale}</Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Segments
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  {map(keys(selectedDimensionInstances), (dimension) => {
                    return (
                      <Box key={dimension}>
                        <Typography
                          sx={{
                            color: (t) => t.palette.primary.main,
                            fontWeight: 'bold',
                          }}
                        >
                          {dimension}
                        </Typography>
                        {map(selectedDimensionInstances[dimension], (d) => (
                          <Box key={d.id}>{d.Name}</Box>
                        ))}
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          xs={state.isPanelCollapsed ? 11.25 : 9.5}
          sx={{
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
        >
          <Card sx={{ background: 'white', height: '100%' }}>
            <CardContent
              sx={{
                paddingBottom: 2,
                marginBottom: 2,
                '&:last-child': {
                  padding: 0,
                },
              }}
            >
              {state.chartLoading && <FullscreenProgress />}

              {state.isEditChartPanelOpen && !state.chartLoading && (
                <EditChartPanel
                  open={state.isEditChartPanelOpen}
                  handleCloseEditChartPanel={handleCloseEditChartPanel}
                  handleSaveEditChartPanel={handleSaveEditChartPanel}
                  selectedModel={state.modelId}
                  selectedModelInstance={state.modelInstanceId}
                  selectedFromScenario={state.fromScenarioId}
                  selectedToScenario={state.toScenarioId}
                  selectedOutput={state.outputId}
                  selectedTimescale={state.timescale}
                  selectedChartType={state.chartType}
                  selectedReport={selectedReport}
                  selectedTimeScaleValue={state.timeScaleValue}
                  selectedColumns={state.columns}
                  selectedDimensionInstances={state.dimensionInstances}
                  isThereAnUnsavedComponent={isThereAnUnsavedComponent}
                  refresh={refresh}
                />
              )}
              {state.isGenerateChartPanelOpen && !state.chartLoading && (
                <GenerateChartPanel
                  open={state.isGenerateChartPanelOpen}
                  data={state.waterFallData}
                  blobURL={state.blobURL}
                  formatKey={state.formatKey}
                  outputName={
                    find(data.rowIdentifiers, { id: state.outputId })?.Name ||
                    ''
                  }
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Waterfall;
