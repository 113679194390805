import React, { FunctionComponent, useMemo } from 'react';
import { Box, Container, IconButton, Link, Slide, Tooltip } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { find, get, map, toString } from 'lodash';
import HC_more from 'highcharts/highcharts-more';

import DownloadIcon from '@mui/icons-material/Download';
import { renderToString } from 'react-dom/server';

import {
  formatParameters,
  IFormatOutputParameters,
} from '../../../../Visualization/formatParameters';
import { truncationValues } from '../../../../Visualization/visualizationConstants';
HC_more(Highcharts);

interface GenerateChartPanelProps {
  data: any[];
  open: boolean;
  formatKey: number | null | undefined;
  outputName: string;
  blobURL: string | undefined;
}

const GenerateChartPanel: FunctionComponent<GenerateChartPanelProps> = ({
  data,
  open,
  formatKey,
  outputName,
  blobURL,
}) => {
  const colorChartByValue = <T extends { y?: number }>(
    data: T[]
  ): (T & { color: string })[] => {
    return map(data, (item: T, index: number, array: T[]) => {
      if (index === 0 || index === array.length - 1) {
        return { ...item, color: '#60759C', isSum: index === array.length - 1 };
      } else if ((item.y as number) >= 0) {
        return { ...item, color: '#64d1a6' };
      } else if ((item.y as number) < 0) {
        return { ...item, color: '#E16A83' };
      }

      return { ...item, color: '#60759C' };
    });
  };
  const decimalPlacesValue = get(
    find(formatParameters, {
      formatKey,
    }) as IFormatOutputParameters,
    'decimalPlaces',
    0
  );

  const formatType = useMemo(
    () =>
      get(
        find(formatParameters, {
          formatKey,
        }) as IFormatOutputParameters,
        'numberType',
        ''
      ),
    [formatKey]
  );

  const prefix =
    get(
      find(formatParameters, {
        formatKey,
      }),
      'numberType'
    ) === '$'
      ? '$'
      : '';

  const suffix =
    get(
      find(formatParameters, {
        formatKey,
      }) as IFormatOutputParameters,
      'numberType'
    ) === 'Percent'
      ? '%'
      : '';

  const magnitudeTruncation = get(
    find(formatParameters, {
      formatKey,
    }) as IFormatOutputParameters,
    'magnitudeTruncation',
    0
  );

  const options: Highcharts.Options = {
    chart: {
      type: 'waterfall',
      height: '500px',
    },

    title: {
      text: '',
    },

    xAxis: {
      type: 'category',
      uniqueNames: false,
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
        },
      },
    },
    yAxis: {
      title: {
        text: ``,
      },
      labels: {
        formatter: function () {
          const self = this;
          return `${prefix}${Highcharts.numberFormat(
            (formatType === 'Percent'
              ? (self.value as number) * 100
              : (self.value as number)) /
              truncationValues[toString(magnitudeTruncation)],
            decimalPlacesValue,
            '.'
          )
            .toString()
            .replaceAll(' ', '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`;
        },
        style: {
          fontSize: '13px',
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      waterfall: {
        lineWidth: 3,
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        const value = `${prefix}${Highcharts.numberFormat(
          (formatType === 'Percent' ? Number(this.y) * 100 : Number(this.y)) /
            truncationValues[toString(magnitudeTruncation)],
          decimalPlacesValue,
          '.'
        )
          .toString()
          .replaceAll(' ', '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              ${suffix}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return renderToString(
          <Box>
            <Box sx={{ fontWeight: 'bold' }}>{outputName}</Box>
            <Box>{value}</Box>
          </Box>
        );
      },
    },

    series: [
      {
        type: 'waterfall',
        pointPadding: 0,
        data: colorChartByValue(
          map(data, (d, idx) => {
            return {
              name: d.ColumnName,
              y: d.Value,
            };
          })
        ),
      },
    ],
  };
  return (
    <Box>
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <Container
          maxWidth={false}
          sx={{
            mb: 2,
            mt: 2,
            pt: 0,
          }}
        >
          <Box
            sx={{
              justifyContent: 'end',
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '12px',
            }}
          >
            <Tooltip title="Download Waterfall Report" arrow placement="left">
              <IconButton
                onClick={() => console.log('ub')}
                component={Link}
                href={blobURL}
              >
                <DownloadIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Container>
      </Slide>
    </Box>
  );
};

export default GenerateChartPanel;
